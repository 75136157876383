<template>
  <v-layout wrap>
    <v-container fluid>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col cols="8">
          <settings-breadcrumbs v-model="breadCrumbProject" />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-alert outlined
            dismissible
            icon="mdi-order-bool-ascending-variant"
            prominent
            type="info"
            class="mb-5"
          >
            Role policies defined below control the user resolution for a IAO role. The rules are evaluated in the following order to determine the correct user:
            <ol class="mt-2">
              <li>Match any policy that includes the current IAO Priority.</li>
              <li>Match any policy that includes the current IAO type.</li>
              <li>Match any policy that includes the current tag set, considering tags individually first and then as a group.</li>
              <li>If there are still multiple matches, the order is used to determine resolution, with the lowest order getting priority.</li>
            </ol>
            <br />
            It's important to accurately specify the IAO Type and Priority, as these are crucial for effective role assignment. Users are selected from the services defined for each rule.
          </v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <policy-role-builder label="Activity Lead" :project="project" />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <policy-role-builder label="Liaison" :project="project" />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <policy-role-builder label="Scribe" :project="project" />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { mapFields } from "vuex-map-fields"

import PolicyRoleBuilder from "@/incident_role/PolicyRoleBuilder.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"

export default {
  name: "IncidentRoleTable",

  components: {
    PolicyRoleBuilder,
    SettingsBreadcrumbs,
  },

  computed: {
    ...mapFields("route", ["query"]),
  },

  created() {
    this.breadCrumbProject = [{ name: this.query.project }]
    this.project = { name: this.query.project }
    this.$watch(
      (vm) => [vm.breadCrumbProject],
      () => {
        this.$router.push({ query: { project: this.breadCrumbProject[0].name } })
      }
    )
  },
}
</script>
